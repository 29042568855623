<template>
  <div class="bg-white rounded-cards shadow-mainShadow cursor-pointer flex flex-col" @click.prevent="onClick">
    <div class="w-full pt-3.5 pr-3 pl-5 mb-10 h-full">
      <div class="flex flex-col items-center mb-1">
        <div class="flex w-full mb-10" :class="job.status == 'closed' ? 'justify-end' : 'justify-between'">
          <component
            :is="xComponentName"
            v-if="job.status != 'closed'"
            :job="job"
            :bidId="job.pro_bid_id"
            modal-classes="text-fonts-tertiary hover:text-black outline-none focus:outline-none"
          >
            <template #trigger>
              <XIcon class="w-6 h-6" />
            </template>
          </component>
          <JobStatus :status="job.state == 'bidding' ? 'bidding' : job.status" />
        </div>

        <template v-if="job.state == 'bidding' && !isPro">
          <div class="flex justify-center w-full mb-3.5">
            <div v-if="job.bid_count > 0" class="flex" :class="{'-ml-10' : job.bid_count > 1}">
              <AvatarImage
                v-for="(url, i) in job.pro_images_urls"
                :key="i"
                :image="url"
                borderColor="border-white"
                borderWidth="border-4"
                class="flex-shrink-0"
                :class="{'-mr-10' : job.bid_count > 1}"
              />
            </div>
            <AccountIcon v-else class="w-20 h-20" strokeWidth="0.5" strokeDasharray="1 1" stroke="#919EC1"/>
          </div>
          <div class="flex flex-col items-center">
            <h4 class="heading-s-700 text-center mb-2" style="min-height: 44px">{{job.title}}</h4>
            <span class="body-xs-400 text-fonts-tertiary">{{bidsAmountText}}</span>
          </div>
        </template>
        <template v-else>
          <AvatarImage :image="isPro ? job.church_logo_url : job.pro_image_url" />
          <h4 class="mb-2 mt-4 text-left body-xs-400">{{ isPro ? job.church_name : job.pro_name }}</h4>
          <span class="heading-s-700 text-center" style="min-height: 44px">{{ job.title }}</span>
        </template>
      </div>
    </div>
    <div class="w-full grid grid-cols-3 divide-x border-t">
      <div class="p-4 flex flex-col items-center">
        <p class="body-xs-700">{{ jobDate }}</p>
        <p class="body-xs-400">{{ job.type == 'one_time' ? 'Due date' : 'Created at' }}</p>
      </div>
      <div class="p-4 flex flex-col items-center">
        <p class="body-xs-700">{{ jobAmount | currency }}</p>
        <p class="body-xs-400">{{ jobAmountText }}</p>
      </div>
      <div class="p-4 flex flex-col items-center">
        <p class="body-xs-700">{{ job.type == 'one_time' ? 'One-Time' : 'Recurring' }}</p>
        <p class="body-xs-400">Job</p>
      </div>
    </div>
  </div>
</template>
<script>
import pluralize from 'pluralize';
import moment from 'moment';
import AvatarImage from "@/components/shared/AvatarImage";
import JobStatus from "@/components/shared/JobStatus";
import EndJobAlertDialog from '@/components/shared/modals/EndJobAlertDialog';
import RemoveJobAlertDialog from '@/components/shared/modals/RemoveJobAlertDialog';
import RemoveBidAlertDialog from '@/components/shared/modals/RemoveBidAlertDialog';
import XIcon from "@/components/shared/svg/XIcon";
import AccountIcon from "@/components/shared/svg/AccountIcon";

export default {
  name: 'Item',
  components: { AvatarImage, JobStatus, EndJobAlertDialog, XIcon, AccountIcon, RemoveJobAlertDialog, RemoveBidAlertDialog },
  props: {
    job: Object,
    isPro: { type: Boolean, default: true}
  },

  computed: {
    bidsAmountText() {
      if (this.isPro) {
        return 'Not assigned to a pro yet';
      }
      if (!this.job.bid_count) {
        return 'No Pros have placed bid yet';
      }
      return `${this.job.bid_count} ${pluralize('Pro', this.job.bid_count)} have placed bids`
    },

    jobDate() {
      if (this.job.type == 'one_time') {
        return moment.utc(this.job.due_date).format("MMM DD, YYYY");
      }
      return moment.utc(this.job.state == 'bidding' ? this.job.created_at : this.job.hiring_created_at).format("MMM DD, YYYY");
    },

    jobAmount() {
      if (this.isPro) {
        return this.job.state == 'bidding' ? this.job.pro_bid_amount : this.job.job_amount ;
      }
      return this.job.state == 'bidding' ? this.job.budget : this.job.job_amount;
    },

    jobAmountText() {
      if (this.isPro) {
        return this.job.state == 'bidding' ? 'Your bid' : 'Job amount';
      }
      return this.job.state == 'bidding' ? 'Your budget' : 'Job amount';
    },

    xComponentName() {
      if (this.isPro) {
        return this.job.state == 'bidding' ? 'RemoveBidAlertDialog' : 'EndJobAlertDialog'
      }
      return this.job.state == 'bidding' ? 'RemoveJobAlertDialog' : 'EndJobAlertDialog'
    }
  },

  methods: {
    onClick() {
      if (this.job.state == 'bidding') {
        this.$router.push({ name: 'JobBids', params: { jobId: this.job.id } }); 
      } else {
        this.$emit('onClick');
      }
    },
  },
}
</script>
