<template>
  <div class="flex justify-center">
    <div v-if="userIsPro" class="w-full lg:w-8/12 ">
      <div class="flex flex-col justify-between mb-10 items-center">
        <h1 class="heading-l-500 mb-10">Hi {{user.full_name}}, let’s find you some jobs.</h1>
        <HireOrLookJobsButton/>
      </div>
      <SearchingUsers class="mx-auto"/>
    </div>
    <div v-else class="flex flex-col items-center">
      <h1 class="heading-l-500 mb-10">Hi {{user.first_name}}, what do you need help with?</h1>
      <SelectJobTemplate @select="postJob"/>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import HireOrLookJobsButton from "@/components/shared/buttons/HireOrLookJobsButton";
import SearchingUsers from "@/components/shared/svg/SearchingUsers";
import SelectJobTemplate from "@/components/shared/SelectJobTemplate";

export default {
  name: 'HomeEmptyState',
  components: { HireOrLookJobsButton, SearchingUsers, SelectJobTemplate },

  methods: {
    ...mapMutations({
      setCurrentTemplate: 'jobs/setCurrentTemplate',
    }),

    postJob(template) {
      this.setCurrentTemplate(template)
      this.$router.push({name: 'PostJob', params: { startFrom2: true }});
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },
  },
}
</script>
