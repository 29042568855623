<template>
  <ButtonIcon @onClick="buttonOnClick" class="flex-shrink-0" fontWeight="font-medium" :text="buttonText">
    <template slot="leftIcon">
      <PlusIcon class="mr-2"/>
    </template>
  </ButtonIcon>
</template>

<script>
import { mapState } from 'vuex';
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import PlusIcon from "@/components/shared/svg/PlusIcon";

export default {
  name: 'HireOrLookJobsButton',
  components: { ButtonIcon, PlusIcon },

  methods: {
    buttonOnClick() {
      const redirectTo = this.userIsPro ? 'Jobs' : 'PostJob'
      this.$router.push({name: redirectTo});
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    buttonText() {
      return this.userIsPro ? 'Look for jobs' : 'Hire a pro'
    },
  },
}
</script>