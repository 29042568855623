<template>
  <Dropdown>
    <template v-slot:button="{ toggle, close }">
      <div style="min-width: 200px" @click="toggle" v-on-clickaway="close" class="rounded-lg bg-white px-4 py-3 flex cursor-pointer">
        <span class="flex mr-2 body-m-400 text-fonts-primary w-full">{{ currentStatus }}</span>
        <button class="flex items-center focus:outline-none">
          <ChevronLeftIcon class="transform -rotate-90 text-auxiliary-blue"/>
        </button>
      </div>
    </template>
    <template slot="content">
      <div v-for="(status, index) in jobStatuses" :key="index" @click.stop="filterBy(status)" role="menuitem" class="dropdown-item">
        <span class="text-blueishGray text-sm">{{ status.title }}</span>
      </div>
    </template>
  </Dropdown>
</template>
<script>
import {mixin as clickaway} from 'vue-clickaway';
import Dropdown from "@/components/shared/Dropdown";
import ChevronLeftIcon from "@/components/shared/svg/ChevronLeftIcon";

export default {
  name: 'FilterJobsByStatus',
  mixins: [clickaway],
  components: { Dropdown, ChevronLeftIcon },
  props: {
    currentStatus: { type: String, default: 'View all jobs' },
  },

  data() {
    return {
      jobStatuses: [
        {
          id: 'all',
          title: 'View all jobs',
        },
        {
          id: 'active',
          title: 'Active',
        },
        {
          id: 'bidding',
          title: 'Bidding',
        },
        {
          id: 'paused',
          title: 'Paused',
        },
        {
          id: 'ended',
          title: 'Ended',
        },
      ],
    }
  },

  methods: {
    filterBy(status) {
      this.$emit('filter', status);
    },
  },
}
</script>
