<template>
  <AlertDialog
    :confirm="`Yes, I want to ${closeJobText} this job`"
    :confirmDelay="4"
    role="menuitem"
    :modalClasses="modalClasses"
    @confirm="endJob($event)"
  >
    <slot name="trigger" />

    <template #title>
      <div class="flex flex-col text-lg mb-2">
        <b>Are you sure you want to {{closeJobText}} this job?</b>
        <p>This action cannot be undone.</p>
      </div>
      <div class="flex flex-col text-base">
        <div v-for="(warning, index) in warnings" :key="index" class="flex my-3">
          <WarningIcon class="mr-2"/>
          <span class="w-full">{{warning}}</span>
        </div>
      </div>
    </template>
  </AlertDialog>
</template>

<script>
import api from '@/api';
import { mapMutations, mapState } from 'vuex'
import WarningIcon from "@/components/shared/svg/WarningIcon";
import AlertDialog from '@/components/shared/AlertDialog';

export default {
  name: 'EndJobAlertDialog',
  components: { WarningIcon, AlertDialog },
  props: {
    job: { type: Object, required: true },
    modalClasses: { type: String, default: 'text-fonts-tertiary hover:text-black' },
  },

  methods: {
    ...mapMutations({
      setJob: "jobs/setJob",
      updateItem: "home/updateItem",
    }),

    async endJob(callback) {
      this.setJob(this.job);
      try {
        const { data } = await api.post(`jobs/${this.job.id}/close`);
        if (data.success) {
            this.setJob(data.data);
            callback.call();
            this.updateItem({ id: this.job.id, data: { status: 'closed' } });
            this.$router.push('/');
        }
      } catch (error) {
        console.log(error);
        this.$toast.error('There was an error. Try again later.');
      }
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    closeJobText() {
      return this.userIsPro ? 'quit' : 'end';
    },

    warnings() {
      if (this.userIsPro) {
        const oneTimeJobWarnings = ['If you quit this job, you will not be able to complete the job or receive payment for it.'];
        const recurringJobWarnings = ['If you quit, you will no longer receive monthly payments for this job.'];
        return this.job.type == 'one_time' ? oneTimeJobWarnings : recurringJobWarnings;
      }
      const churchWarnings = this.job.prorated_amount && this.job.prorated_amount > 0 ? [`A prorated amount of $${this.job.prorated_amount} will be charged today.`] : [];
      return this.job.type == 'one_time' ? '' : churchWarnings;
    },
  },
}
</script>