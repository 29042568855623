<template>
  <div>
    <div class="flex justify-between mb-10 items-center">
      <template v-if="Object.keys(user).length > 0">
        <h1 class="heading-l-500">Hi {{ user.full_name }}, here {{userIsPro ? 'are your jobs' : 'is your team'}}</h1>
      </template>
      <VueSkeletonLoader
        v-else
        :color="baseColor"
        :wave-color="waveColor"
        :rounded="true"
        :width="570"
        :height="50"
        animation="fade"
      />
      <VueSkeletonLoader
        :color="baseColor"
        :wave-color="waveColor"
        :rounded="true"
        :width="140"
        :height="50"
        animation="fade"
      />
    </div>
    <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div v-for="a in amount" :key="a">
        <span class="flex flex-col bg-white px-4 pt-3.5 rounded-cards items-center">
          <div class="flex w-full justify-end mb-10">
            <VueSkeletonLoader
              :color="baseColor"
              :wave-color="waveColor"
              :rounded="true"
              radius="5"
              :width="102"
              :height="31"
              animation="fade"
            />
          </div>
          <div class="flex flex-col items-center">
            <VueSkeletonLoader
              type="circle"
              :color="baseColor"
              :wave-color="waveColor"
              :width="66"
              :height="66"
              animation="fade"
              class="mb-4"
            />
            <VueSkeletonLoader
              :color="baseColor"
              :wave-color="waveColor"
              :rounded="true"
              radius="5"
              :width="104"
              :height="17"
              animation="fade"
              class="mb-3"
            />
            <VueSkeletonLoader
              :color="baseColor"
              :wave-color="waveColor"
              :rounded="true"
              radius="5"
              :width="185"
              :height="17"
              animation="fade"
              class="mb-8"
            />
          </div>
          <VueSkeletonLoader
            :color="baseColor"
            :wave-color="waveColor"
            :rounded="true"
            radius="5"
            :width="200"
            :height="65"
            animation="fade"
            class="mb-2"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
import { mapState } from 'vuex';
export default {
  name: 'HomeSkeleton',
  components: { VueSkeletonLoader },
  data() {
    return {
      amount: 8,
      baseColor: "#E9DBCE",
      waveColor: "#413D51",
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },
  },
};
</script>
