<template>
  <div v-if="!loading">
    <div v-if="!loggedIn">
      <div class="flex justify-center">
        <div class="flex flex-col items-center">
          <h1 class="heading-l-500 mb-10">Hi, what do you need help with?</h1>
          <SelectJobTemplate @select="onSelectTemplate" />
        </div>
      </div>
    </div>
    <div v-else-if="jobs.length">
      <div class="flex justify-between mb-10 xl:items-center flex-col xl:flex-row">
        <h1 class="heading-l-500">Hi {{ user.first_name }}, here are {{userIsPro ? '' : 'all'}} your jobs!</h1>
        <div class="flex space-x-3.5 items-center">
          <FilterJobsByStatus :currentStatus="currentStatus" @filter="filterJobsByStatus" />
          <HireOrLookJobsButton />
        </div>
      </div>
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Item v-for="(job, index) in jobs" :key="`item-${index}`" :job="job" :isPro="userIsPro" @onClick="openJobModal(job.id)"/>
      </div>
    </div>
    <HomeEmptyState v-else/>
  </div>
  <div v-else>
    <HomeSkeleton />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import HireOrLookJobsButton from "@/components/shared/buttons/HireOrLookJobsButton";
import Item from "@/components/home/Item";
import HomeEmptyState from "@/components/home/HomeEmptyState";
import FilterJobsByStatus from "@/components/home/FilterJobsByStatus";
import HomeSkeleton from "@/components/skeleton/HomeSkeleton";
import SelectJobTemplate from "@/components/shared/SelectJobTemplate";

export default {
  name: 'Home',
  components: { HomeEmptyState, HomeSkeleton, Item, HireOrLookJobsButton, FilterJobsByStatus, SelectJobTemplate },
  data() {
    return {
      loading: true,
      currentStatus: 'View all jobs',
    }
  },
  async mounted() {
    try {
      await this.getUser();
      await this.fetch();
      this.dashlyAuth();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions({
      fetch: 'home/fetch',
      getUser: 'auth/getUser',
    }),

    ...mapMutations({
      showModal: 'general/showModal',
    }),

    openJobModal(jobId) {
      this.showModal({ componentName: 'JobModal', props: { jobId }});
    },

    dashlyAuth() {
      if(this.dashlyToken) {
        window.dashly.auth(this.user.id, this.dashlyToken);
        window.dashly.identify({
          '$name': this.user.full_name,
          '$email': this.user.email,
          'Type': this.user.userable_type,
        })
      }
    },

    async filterJobsByStatus(status) {
      this.loading = true;
      await this.fetch(status.id);
      this.currentStatus = status.title;
      this.loading = false;
    },

    ...mapMutations({
      setCurrentTemplate: "jobs/setCurrentTemplate"
    }),

    onSelectTemplate(template) {
      this.setCurrentTemplate(template);
      this.showModal({
        componentName:
        'SignupModal',
        minWH: 'min-width: 500px; min-height: 640px;',
        maxWH: 'max-w-md max-h-96 lg:max-h-180 2xl:max-h-192'
      });
    },
  },

  computed: {
    ...mapState({
      jobs: state => state.home.items,
      user: state => state.auth.user,
      dashlyToken: state => state.home.dashlyToken,
    }),

    ...mapGetters({
      loggedIn: 'auth/loggedIn',
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },
  }
}
</script>
